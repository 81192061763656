import * as React from 'react';

const Support = () =>
    <div className="main-content">
        <section className="section-fade-in section-space-print section-spacer">
            <div className="container">
                <div className="justify-content-center row">
                    <div className="col-11 col-lg-12 fade-in">
                        <h2 className="h6 mb-2 text-primary"><p id="breadcrumbs"><span><span><a href="/">Home</a></span> <span className="yoast-sep mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="10.438" height="9.409" viewBox="0 0 10.438 9.409">
                            <g id="Group_2183" data-name="Group 2183" transform="translate(-141.201 -213.442)">
                                <line id="Line_11" data-name="Line 11" x2="8.629" transform="translate(141.951 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_12" data-name="Line 12" x2="3.19" y2="3.646" transform="translate(147.39 214.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_13" data-name="Line 13" y1="3.646" x2="3.19" transform="translate(147.39 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                            </g>
                        </svg></span> <span className="breadcrumb_last" aria-current="page">YourVue Support</span></span></p></h2>
                        <p className="display-6 fw-bold mb-5">YourVue Support</p>
                        <div>
                            <p className="p1">Our developers work diligently to keep YourVue running smoothly, and our researchers review every survey before it appears in the app for you to complete.</p>
                            <p className="p1">If you encounter a problem with the YourVue app, or any of our surveys, our support team are here to help!</p>
                            <p className="p1">You can message us directly using the “Contact” button in the YourVue app, or contact us by email: <a href="mailto:support@your-vue.com"><span className="s1">support@your-vue.com</span></a></p>
                            <p className="p1">Our support team are available Monday to Friday and aim to respond within two business days.</p>
                            <p className="p1">For the quickest resolution, please send your email from the email address shown at the top of your Profile in the app or let us know that email and the login method you are using, e.g. Google.</p>
                            <p className="p1">A screenshot showing any issue you encountered can also be very helpful.</p>
                            <p className="p1">If you have a general question or would like to learn more about how YourVue works, you may find the answer faster in our <a href="faqs"><span className="s1">FAQs</span></a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default Support;