import * as React from 'react';

const PrivacyPolicy = () => 
    <div className="main-content">
        <section className="section-fade-in section-space-print section-spacer">
            <div className="container">
                <div className="justify-content-center row">
                    <div className="col-11 col-lg-12 fade-in">
                        <h2 className="h6 mb-2 text-primary"><p id="breadcrumbs"><span><span><a href="https://your-vue.com/">Home</a></span> <span className="yoast-sep mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="10.438" height="9.409" viewBox="0 0 10.438 9.409">
                            <g id="Group_2183" data-name="Group 2183" transform="translate(-141.201 -213.442)">
                                <line id="Line_11" data-name="Line 11" x2="8.629" transform="translate(141.951 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_12" data-name="Line 12" x2="3.19" y2="3.646" transform="translate(147.39 214.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_13" data-name="Line 13" y1="3.646" x2="3.19" transform="translate(147.39 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                            </g>
                        </svg></span> <span className="breadcrumb_last" aria-current="page">Privacy Policy</span></span></p></h2>
                        <p className="display-6 fw-bold mb-5">Privacy Policy</p>
                        <div>
                            <p>YourVue is a branded mobile app and website owned and operated by Savanta, a trading name of Savanta Group Limited (“We”, or “Savanta”).</p>
                            <p><b>We are committed to protecting and respecting your privacy</b></p>
                            <p>At Savanta we take your privacy seriously. Please view our Privacy Policy <a href="https://savanta.com/privacy-policy/">here</a>.</p>
                            <p><b>Contact us</b></p>
                            <p>Questions, comments, and requests regarding this privacy policy are welcomed and should be addressed to <a href="mailto:support@your-vue.com?subject=YourVue%20Support%20Privacy%20Policy">support@your-vue.com</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default PrivacyPolicy;