import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const EmailVerified = () => {
    return (
        <Box sx={{ m: 5 }}>
            <Typography variant="h3" color="text.primary" paragraph sx={{ mt: 2 }}>
                Email verification
            </Typography>
            <Typography paragraph >
                Please open the verification email and click the link <b>on the device where the YourApp is installed</b>.
            </Typography>
            <Typography paragraph >
                Thank you for using YourVue!
            </Typography>
        </Box>
    );
}

export default EmailVerified;