import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Image } from 'mui-image';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

type InstallerData = {
    installUrl: string;
    callbackUrl: string;
    validClient: boolean;
};

const Landing = () => {

    const linkRef = React.useRef<HTMLAnchorElement>(null);
    const { sid } = useParams();
    const [data, setData] = React.useState<InstallerData>();

    React.useEffect(() => {
        (async () => {
            const response = await fetch(`/api/callbacks/coreg/mvf/confirm/${sid}`);
            setData(await response.json() as InstallerData);
        })();
    }, [])

    if (!data) return <Box height={365} display='flex' justifyContent='center' alignItems='center' ><CircularProgress /></Box>;

    // Auto click the link after a few seconds
    if (data.validClient) {
        setTimeout(() => {
            linkRef.current?.click();
        }, 5000);
    }

    return (<Link sx={{ textDecoration: 'none', color: '#000000' }} ref={linkRef} href={data.validClient ? data.installUrl : ''}>
        <Box
            sx={{
                bgcolor: 'background.paper',
                pt: { 'xs': 4, 'sm': 6, 'md': 8 },
                pb: { 'xs': 2, 'sm': 3, 'md': 4 },
            }}
        >
            {data.validClient ?

                <Container>
                    <Typography textAlign="center" variant="h4">Taking you to the YourVue app installer</Typography>
                    <Typography textAlign="center" variant="h5">If this does not happen within 5 seconds, please click here</Typography>
                </Container>

                :
                <Container>
                    <Typography textAlign="center" variant="h4">Please click the email confirmation download link <b>on your phone</b>
                        <Image style={{ verticalAlign: 'middle', height: 200, width: 200 }} src="/phone-click.svg" /></Typography>
                </Container>
            }
        </Box>

        <Container sx={{ py: 4 }} maxWidth="lg">
            <Grid container spacing={4} alignItems="center" justifyContent="center" >
                <Grid item xs={6} sm={4} md={4}>
                    <Image src="/screenshot1.jpeg" />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <Image src="/screenshot2.jpeg" />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <Image src="/screenshot3.jpeg" />
                </Grid>
            </Grid>
        </Container>

        {data.validClient && <img src={data.callbackUrl} alt='callback' />}
    </Link>
    );
}

export default Landing;