import * as React from 'react';

const DataDeletion = () =>
    <div className="main-content">
        <section className="section-fade-in section-space-print section-spacer">
            <div className="container">
                <div className="justify-content-center row">
                    <div className="col-11 col-lg-12 fade-in">
                        <h2 className="h6 mb-2 text-primary"><p id="breadcrumbs"><span><span><a href="https://yourvue.wpenginepowered.com/">Home</a></span> <span className="yoast-sep mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="10.438" height="9.409" viewBox="0 0 10.438 9.409">
                            <g id="Group_2183" data-name="Group 2183" transform="translate(-141.201 -213.442)">
                                <line id="Line_11" data-name="Line 11" x2="8.629" transform="translate(141.951 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_12" data-name="Line 12" x2="3.19" y2="3.646" transform="translate(147.39 214.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_13" data-name="Line 13" y1="3.646" x2="3.19" transform="translate(147.39 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                            </g>
                        </svg></span> <span className="breadcrumb_last" aria-current="page">YourVue Data Deletion process</span></span></p></h2>
                        <p className="display-6 fw-bold mb-5">YourVue Data Deletion process</p>
                        <div>
                            <p className="p1">We hope you enjoy using YourVue; however, should you wish to delete your personal data and unsubscribe from YourVue, you can do this in the following ways:</p>
                            <ul className="ul1">
                                <li className="li1">Open the YourVue app on your personal device, select the “Delete profile” button on the Profile page within the app. Simply deleting the YourVue app from your device will not delete your personal data, please ensure you click the “Delete profile” button from the Profile page if you wish to unsubscribe, before removing the app.</li>
                                <li className="li1">Alternatively, please email <a href="mailto:support@your-vue.com"><span className="s2">support@your-vue.com</span></a> from the email address you used to sign up for the account, to request removal. Please state clearly in your email that you want to unsubscribe and delete your personal data.
                                </li>
                            </ul>
                            <p className="p1"><b>IMPORTANT NOTICE:</b> It is not possible to redeem rewards after your data has been deleted. Therefore, we recommend you first check your balance and redeem rewards of £5 or over, prior to deleting your data.</p>
                            <p className="p1">We will usually need to retain these things to ensure we can fulfil legal obligations, for our financial records and other legitimate interests:</p>
                            <ul className="ul1">
                                <li className="li1">Details of any reward payments attempted/made to PayPal and the associated payment account.</li>
                                <li className="li1">Any non-personally identifiable information and survey data provided (pseudonymised).</li>
                                <li className="li1">A record of the deletion request.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default DataDeletion;