import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Stack } from '@mui/material';

const Unsubscribe = () => {
    const { id } = useParams();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [unsubscribed, setUnsubscribed] = React.useState<boolean>(false);

    const unsubscribe = () => {
        setLoading(true);
        fetch(`https://api.your-vue.com/api/callbacks/unsubscribe/${id}`, { method: "POST" })
            .then(() => setUnsubscribed(true))
            .finally(() => setLoading(false));
    };

    const getContent = () => {
        if (loading) {
            return (
                <Stack alignItems="center">
                    <CircularProgress />
                </Stack>
            );
        }
        if (unsubscribed) {
            return (
                <Typography color="text.primary" paragraph sx={{ mt: 2 }}>You are now unsubscribed.</Typography>
            );
        }
        return (
            <>
                <Typography color="text.primary" paragraph sx={{ mt: 2 }}>
                    Please confirm that you want to unsubscribe from all further emails.
                </Typography>
                <Button onClick={unsubscribe} variant='contained'>Unsubscribe</Button>
            </>
        );
    };

    return (
        <Box sx={{ m: 5 }}>
            <Typography variant="h3" color="text.primary" paragraph sx={{ mt: 2 }}>
                Unsubscribe from emails
            </Typography>
            {getContent()}
        </Box>
    );
}

export default Unsubscribe;