import * as React from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions';
import Support from './Support';
import DataDeletion from './DataDeletion';
import Landing from './Landing';
import PrivacyPolicy from './PrivacyPolicy';
import EmailVerified from './EmailVerified';
import Unsubscribe from './Unsubscribe';
import LandingCoReg from './LandingCoReg';
import OpcLink from './OpcLink';
import Faqs from './Faqs';
import { Helmet } from 'react-helmet';

const Routing = () => <Router>
    <Routes>
        <Route path="/coreg/:sid" element={<LandingCoReg />} />
        <Route path="/" element={<Main />}>
            <Route path="" element={<Landing />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="support" element={<Support />} />
            <Route path="emailVerified/*" element={<EmailVerified />} />
            <Route path="termsandconditionsandprivacypolicy" element={<TermsAndConditions />} />
            <Route path="termsandconditions" element={<TermsAndConditions />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="datadeletion" element={<DataDeletion />} />
            <Route path="unsubscribe/:id" element={<Unsubscribe />} />
            <Route path="opc" element={<OpcLink />} />
            <Route path="*" element={<div>Not found</div>} />
        </Route>
    </Routes>
</Router>;

const Header = () => {
    return (<>
        {window.location.pathname === '/' ?
            <nav className="main-nav navbar w-100 z-1" style={{ position: 'fixed', top: '0px', left: '0px' }}>
                <div className="container "> <a className="navbar-brand" href="/" ><img alt="" src="assets/svg/sv-yv-footer-logo.svg" className="hedaer-logo" /><span className="visually-hidden">YourVue</span></a>
                    <ul className="d-md-block d-none me-3 me-md-4 ms-auto navbar-nav">
                        <li className="nav-item"> <a className="fw-bold link-light nav-link" href="faqs">FAQs</a>
                        </li>
                    </ul><a className="btn btn-primary header-signup-button rounded-pill text-white" href="#gettheapp">Get the app <svg xmlns="http://www.w3.org/2000/svg" width="5.816" height="10.571" viewBox="0 0 5.816 10.571" className="ms-3">
                        <g id="sign-up" transform="translate(-181.243 -964.991)">
                            <line id="Line_18" data-name="Line 18" x2="3.699" y2="4.227" transform="translate(182.301 966.049)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                            <line id="Line_19" data-name="Line 19" y1="4.227" x2="3.699" transform="translate(182.301 970.277)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                        </g>
                    </svg></a>
                </div>
            </nav>
            :
            <section className="d-flex flex-column justify-content-center overflow-hidden" data-pg-name="Hero">
                <nav className="main-nav navbar w-100 z-1">
                    <div className="container "> <a className="navbar-brand" href="/"><img alt="" src="assets/svg/sv-yv-header-colour-logo.svg" className="hedaer-logo" /><span className="visually-hidden">YourVue</span></a>
                        <ul className="d-md-block d-none me-3 me-md-4 ms-auto navbar-nav">
                            <li className="nav-item"> <a className="fw-bold nav-link" href="faqs">FAQs</a>
                            </li>
                        </ul>
                        <a className="btn btn-primary header-signup-button rounded-pill text-white" href="#gettheapp">Get the app <svg xmlns="http://www.w3.org/2000/svg" width="5.816" height="10.571" viewBox="0 0 5.816 10.571" className="ms-3">
                            <g id="sign-up" transform="translate(-181.243 -964.991)">
                                <line id="Line_18" data-name="Line 18" x2="3.699" y2="4.227" transform="translate(182.301 966.049)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                                <line id="Line_19" data-name="Line 19" y1="4.227" x2="3.699" transform="translate(182.301 970.277)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                            </g>
                        </svg></a>
                    </div>
                </nav>
            </section>
        }
    </>)
}

const Footer = () => {
    return (<>
        <footer className="bg-white pb-5 position-relative z-2">
            <section data-pg-name="Anytime" className="bg-white position-relative section-fade-in section-spacer z-2">
                <div className="container">
                    <div className="align-items-center justify-content-center row">
                        <div className="col-11 col-lg-12 fade-in">
                            <div className="bg-primary border-0 card hero-header pb-5 pt-5 rounded-5" id="gettheapp">
                                <div className="align-items-center flex-column g-0 h-100 justify-content-center row">
                                    <div className="col-10 col-lg-8 col-xxl-6  text-center">
                                        <div className="card-body">
                                            <p className="display-6 fw-bold mb-2 text-white">Get the app!</p>
                                            <p className="d-lg-block d-none footer-text text-white">Scan the QR code on your smartphone or tablet to get the app and start turning your views into cash!</p>
                                            <p className="d-lg-none footer-text text-white">Tap the button on your smartphone or tablet to get the app and start turning your views into cash!</p>
                                            <div className="d-lg-flex d-none dl-links flex-md-row flex-row justify-content-md-center mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=com.savanta.yourvue&hl=en&utm_source=home&utm_medium=&utm_campaign=recruitment"
                                                    target="_blank"
                                                    className="d-flex flex-column footer-link link-light mt-4 pb-3 pe-4 ps-4 pt-2 text-decoration-none appstore-link"
                                                >
                                                    <span className="fw-bold mb-1 me-1 rating">Android</span><img alt="" src="assets/svg/sv-yv-android-qr-yv.svg" className="flex-grow-0 qr-link" />
                                                </a>
                                                <a href="https://apps.apple.com/gb/app/yourvue/id6449937378"
                                                    className="d-flex flex-column footer-link link-light mt-4  pb-3 pe-4 ps-4 pt-2 text-decoration-none appstore-link"
                                                    target="_blank"
                                                >
                                                    <span className="fw-bold mb-1 me-1 rating">iOS</span><img alt="" src="assets/svg/sv-yv-ios-qr-yv.svg" className="flex-grow-0 qr-link" />
                                                </a>
                                            </div>
                                            <div className="d-flex d-lg-none dl-links flex-column flex-md-row justify-content-center mt-4">
                                                <a href="https://play.google.com/store/apps/details?id=com.savanta.yourvue&hl=en&utm_source=home&utm_medium=&utm_campaign=recruitment"
                                                    target="_blank"
                                                    className="align-items-center d-flex flex-column fw-bold mb-4 me-md-2 ms-md-2 text-decoration-none text-white appstore-link"
                                                >
                                                    <img alt="" src="assets/svg/sv-yv-google-play.svg" className="footer-store-link store-link" />
                                                </a>
                                                <a href="https://apps.apple.com/gb/app/yourvue/id6449937378"
                                                    className="align-items-center d-block d-flex d-lg-inline-block flex-column fw-bold me-md-2 ms-md-2 mt-lg-4 text-decoration-none text-white appstore-link"
                                                    target="_blank"
                                                >
                                                    <img alt="" src="assets/svg/sv-yv-download-app-store.svg" className="footer-store-link store-link" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container pe-4 ps-4">
                <div className="row">
                    <div className="col-md-4">
                        <a className="navbar-brand" href="/"><img alt="" src="assets/svg/sv-yv-header-colour-logo.svg" /><span className="visually-hidden">YourVue</span></a>
                    </div>
                </div>
                <div className="mt-4 row">
                    <div className="col">
                        <p className="mb-0 text-muted">&copy;YourVue 2024</p>
                    </div>
                    <div className="col-12 col-md-9 mt-5 mt-md-0 text-md-end">
                        <ul className="d-flex flex-column flex-md-row justify-content-end list-unstyled mt-2 mt-md-0">
                            <li className="mb-2 mb-md-0 me-3">
                                <a href="privacypolicy" className="text-muted underline-link">Privacy Policy</a>
                            </li>
                            <li className="mb-2 mb-md-0 me-3 ms-md-4"><a href="termsandconditions" className="text-muted underline-link">Terms & Conditions</a>
                            </li>
                            <li className="mb-2 mb-md-0 me-3 ms-md-4"><a href="datadeletion" className="text-muted underline-link">Data Deletion</a>
                            </li>
                            <li className="mb-2 mb-md-0 ms-md-4 "><a href="support" className="text-muted underline-link">Support</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <Helmet>
            <script src="assets/js/popper.min.js"></script>
            <script src="bootstrap/js/bootstrap.min.js"></script>
            <script src="pgia/lib/pgia.js"></script>
        </Helmet>
    </>)
}

const Main = () => {
    return (<>
        <Header />
        <main><Outlet /></main>
        <Footer />
    </>)
}

const App = () => (
    <Routing />
);


export default App;