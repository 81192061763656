import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const OpcLink = () => {
    return (
        <Box sx={{ m: 5 }}>
            <Typography variant="h3" color="text.primary" paragraph sx={{ mt: 2 }}>
                Get YourVue!
            </Typography>
        </Box>
    );
}

export default OpcLink;