import * as React from 'react';

const TermsAndConditions = () => 
    <div className="main-content">
        <section className="section-fade-in section-space-print section-spacer">
            <div className="container">
                <div className="justify-content-center row">
                    <div className="col-11 col-lg-12 fade-in">
                        <h2 className="h6 mb-2 text-primary"><p id="breadcrumbs"><span><span><a href="/">Home</a></span> <span className="yoast-sep mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="10.438" height="9.409" viewBox="0 0 10.438 9.409">
                            <g id="Group_2183" data-name="Group 2183" transform="translate(-141.201 -213.442)">
                                <line id="Line_11" data-name="Line 11" x2="8.629" transform="translate(141.951 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_12" data-name="Line 12" x2="3.19" y2="3.646" transform="translate(147.39 214.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                                <line id="Line_13" data-name="Line 13" y1="3.646" x2="3.19" transform="translate(147.39 218.146)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5"></line>
                            </g>
                        </svg></span> <span className="breadcrumb_last" aria-current="page">Terms &amp; Conditions</span></span></p></h2>
                        <p className="display-6 fw-bold mb-5">Terms &amp; Conditions</p>
                        <div>
                            <p>YourVue is a market research access panel available exclusively via mobile app at <a href="https://your-vue.com/">https://your-vue.com/</a> (“YourVue”), owned and operated by Savanta, a trading name of Savanta Group Limited (“Savanta”).</p>
                            <p>Please read this carefully because it sets out the terms of a legally binding agreement between you and Savanta.</p>
                            <p>As a YourVue member (“Member”), you will have the opportunity to participate in market research activities (“Surveys”) in exchange for incentive points (“Incentives”), which can be redeemed for cash rewards (“Rewards”).</p>
                            <p>These terms and conditions replace all previous terms and conditions for YourVue. We may update these terms and conditions for legal or regulatory reasons, or to reflect changes in our services or business practices. We will provide notice of any significant changes in the Section headed “Changes to terms and conditions” below, and notify active Members via the YourVue app. Any changes will become effective as soon as we post them here on <a href="https://your-vue.com/termsandconditions">https://your-vue.com/</a>.</p>
                            <p>We may in exceptional circumstances cease to operate YourVue, and we will try to give you reasonable notice of this, if possible. If we do cease to operate YourVue, then we will have no further obligation to you – see the “Termination” section below.</p>
                            <p><b>Membership</b></p>
                            <p>YourVue membership is open only to residents in the UK aged 16 years or over.</p>
                            <p>YourVue membership is free and the YourVue app is available to install free of charge from Google Play and the App Store.</p>
                            <p>To become a Member of YourVue, you first need to install the YourVue app on a personal mobile device – a smartphone or tablet. YourVue is compatible with Android 7 and above, and iOS/iPadOS 15.0 or later. When you first open the app you’ll be prompted to provide a login email address and password for your account, or to login securely with Google or Apple, then answer a few questions about yourself to create your profile and complete registration.</p>
                            <p>Should you be accepted as a Member, you will be notified of surveys by the YourVue app on your mobile device, and may be invited to participate in other market research activities (e.g. telephone surveys, product tests, or focus groups). You are free to complete as many or as few surveys you wish.</p>
                            <p><b>Registration</b></p>
                            <p>You may only open one account per person. Accounts are not transferable. We will try to process your membership promptly, but we do not guarantee that your membership will be activated by any specified time.</p>
                            <p>You are responsible for keeping your device and login details safe. We will not be liable for any unauthorised use of your account, which includes unauthorised use of your email address, password, and Incentives.</p>
                            <p>We reserve the right to cancel or reject your membership if your details are found to be incorrect, for breach of these terms, or for any or no reason that we deem legitimate.</p>
                            <p><b>Incentives, Rewards and Competition entry</b></p>
                            <p>You may earn Incentives by completing surveys. The amount of Incentives accrued for participation in surveys will be notified to Members via the YourVue app. For more information on Incentives and Rewards, see our <a href="faqs">Frequently Asked Questions</a>.</p>
                            <p>Incentives can only be earned by following all and any instructions regarding the earning of such Incentives. Failure to follow any instructions may result in no Incentives being earned and you will have no recourse against Savanta Group Limited.</p>
                            <p>We will endeavour to credit Incentives into your account immediately upon your completion of relevant surveys, but we do not guarantee that Incentives will be credited by any particular time.</p>
                            <p>No earned Incentives are ever available for redemption as Rewards until you have earned a minimum of £5 of Incentives.</p>
                            <p>We will endeavour to pay your Rewards to PayPal or Love2Shop immediately upon redemption via the YourVue app, but we do not guarantee that payment will be credited by any particular time.</p>
                            <p>We reserve the right to change the range of surveys and related activities through which Incentives may be earned and to vary the frequency of invitation to such activities. We also reserve the right to alter the level of Incentives required before such Incentives can be redeemed as Rewards.</p>
                            <p>Incentives and Rewards may not be transferred to other YourVue accounts nor pooled together in any manner and the sale or barter of Incentives and Rewards is strictly prohibited. Incentives have no monetary value and cannot be used for any purpose other than redeeming the designated Reward.</p>
                            <p>It is our understanding that the payment of Rewards to Members for completing surveys may become taxable income. Members are not our employees, and it is the sole responsibility of Members to report and pay all taxes due in respect of income earned from completing surveys to the relevant tax authorities. We will not be held liable for any taxation, or related costs, resulting from any payments made to Members and Members indemnify us fully against such liability.</p>
                            <p>From time to time, we may run competition or prize draws and may automatically enter all Members. Such competition and draws will be governed by the respective terms and conditions which will be displayed for each competition and/or prize draw.</p>
                            <p><b>Completing Surveys</b></p>
                            <p>You agree to provide truthful and well considered answers to our market research activities.</p>
                            <p>We trust that Savanta and its clients can rely on the answers you provide to be truthful and correct.</p>
                            <p>We reserve the right to withhold Incentives if, in our reasonable opinion, your survey question responses are untruthful or not sufficiently well considered.</p>
                            <p><b>Termination</b></p>
                            <p>You can terminate your account at any time by clicking ‘Delete profile’ from the Profile page in the YourVue app, or by email to <a href="mailto:support@your-vue.com?subject=YourVue%20Support%20-%20Unsubscribe">support@your-vue.com</a>, prior to deleting the app from your device.</p>
                            <p>Deleting your profile will anonymise your personal information and any unclaimed Incentives will be forfeited and cannot be recovered if you later reinstall the app and create a new account.</p>
                            <p>We therefore recommend you redeem any Incentives over £5 as Rewards, prior to you terminating your account.</p>
                            <p>We reserve the right to terminate your account and/or cease to operate the YourVue platform and provide access to services on the YourVue platform at any time immediately and without cause and with or without notice.</p>
                            <p><b>Intellectual Property Ownership</b></p>
                            <p>You acknowledge that:</p>
                            <ul>
                                <li>Savanta are the owner or the licensee of all intellectual property rights in the YourVue platform and all content/services available on it (“YourVue Content”). No intellectual property rights in any YourVue Content are transferred to you; and</li>
                                <li>all survey responses, data and information provided by you on the YourVue platform, including as part of a survey (“Survey Content”), becomes the Intellectual Property of Savanta Group Limited and/or its client or partners. You waive any moral rights you may have in regard to the Survey Content.</li>
                            </ul>
                            <p>You agree that you will:</p>
                            <ul>
                                <li>only provide Survey Content that is your original content and will not infringe the copyright or other rights of any third party when publishing Survey Content;</li>
                                <li>not post, link to or otherwise publish any Survey Content that is threatening, offensive, libellous, indecent or otherwise unlawful;</li>
                                <li>not post comments that are discriminatory in nature, for example, comments which make attacks on the grounds of race, religion, sex, gender, sexual orientation, disability or age; and</li>
                                <li>not post, provide or transmit any Survey Content that contains software viruses, files or code designed to interrupt, destroy or limit the functionality of YourVue or any computer software or equipment.</li>
                            </ul>
                            <p><b>Confidential Information and Data</b></p>
                            <p>Certain information provided to you on the YourVue panel will be confidential in nature. You agree to not disclose any of such information relating to any third parties. We reserve the right to terminate your membership if we reasonably believe that you are in breach of this term.</p>
                            <p>We will use and process your data in accordance with the terms of our <a href="privacypolicy">Privacy Policy</a>. Completion of surveys may involve us collecting sensitive personal data from you, but we will seek your express consent before collecting any such data.</p>
                            <p>On registration and when completing surveys, you must provide us with accurate, complete information and it is your responsibility to update your registration information. We trust Savanta and its clients can rely on any information you provide us.</p>
                            <p>If you provide us with an email address or install the YourVue app on a device that will result in any communications Savanta may send you being sent to you via a network or device operated or owned by a third party (e.g. your employer or college) then you promise that you are entitled to receive those messages. You also agree that Savanta Group Limited may stop sending messages to you without notifying you.</p>
                            <p><b>Limitation of liability</b></p>
                            <p>This section is important and you should read it carefully.</p>
                            <p>Nothing in these terms shall exclude or in any way limit either party’s liability for fraud, death or personal injury caused by its negligence or any other liability to the extent such liability may not be excluded or limited as a matter of law.</p>
                            <p>Subject to this:</p>
                            <ul>
                                <li>our maximum aggregate liability under or in connection with these terms whether in contract, tort (including negligence) or otherwise shall in no circumstances exceed an amount equal to the Sterling equivalent of all Incentives in your account at the time of any event giving rise to liability; and</li>
                                <li>we shall not be liable to you, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with these terms for:</li>
                            </ul>
                            <ul>
                                <li>any loss (whether direct, indirect or consequential) of profits, sales or business, agreements or contracts, anticipated savings or goodwill;</li>
                                <li>loss of use or corruption of software, data or information; or</li>
                                <li>any special, indirect, consequential or pure economic loss, costs, damages, charges or expenses.</li>
                            </ul>
                            <p>You are responsible for paying any internet connection or telecommunications charges that you may incur by accessing YourVue or using the services available on it. For example, if you use any of our mobile services then your network operator may charge you for data services.</p>
                            <p>If this limitation of liability provision shall be deemed unenforceable, either in whole or in part, then such parts as are unenforceable shall be deleted, the remaining parts shall remain in full force and effect and the liability of Savanta Group Limited shall be limited to the greatest extent permitted by applicable law.</p>
                            <p><b>Disclaimer</b></p>
                            <p>To the extent permitted by applicable law, you acknowledge that:</p>
                            <ul>
                                <li>use of the YourVue app is solely at your own risk; and</li>
                                <li>the YourVue app is provided on a strictly “as is” and “as available” basis, without representation or warranty of any kind.</li>
                            </ul>
                            <p><b>Jurisdiction and Governing Law</b></p>
                            <p>These terms, and any dispute or claim arising out of or in connection with these terms or their subject-matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, the law of England and Wales.</p>
                            <p>You irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these terms or their subject-matter or formation (including non-contractual disputes or claims).</p>
                            <p><b>Notices and getting in touch</b></p>
                            <p>A notice (other than a notice in any legal proceedings) given by one party to the other under this Agreement will be properly served if it is in English and sent to:</p>
                            <ul>
                                <li>in the case of notices to Savanta, 60 Great Portland Street, London, W1W 7RT; and</li>
                                <li>in the case of notices to you, the relevant email address we have in our Member database.</li>
                            </ul>
                            <p>If you have any questions about these terms or would like to get in touch, you can contact us at <a href="mailto:support@your-vue.com?subject=YourVue%20Support%20-%20Terms%20and%20Conditions">support@your-vue.com</a>.</p>
                            <p><b>Changes to Terms and Conditions</b></p>
                            <p>These terms were published on 18.06.2024 and replace with immediate effect the terms and conditions published prior to that date.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default TermsAndConditions;